import cx from 'classnames';
import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import React, { useMemo } from 'react';
import Quota from '../../components/Quota';
import { QuotaMode } from '../../components/Quota/Quota';
import { replaceValues } from '../../components/utils';
import i18n from '../../translations';
import { findOverlappingSession } from '../../utils/sessionUtils';
import { Error, Errors } from '../errors';
import { FieldProps } from '../field.types';
import './WorkshopRegistrationV2.scss';
import { Workshop, WorkshopRegistration } from './workshop.types';

type WorkshopRegistrationUIProps = {
  columns?: number;
  showDateOnly: boolean;
  enableRadio?: boolean;
  showCategory?: boolean;
};

type WorkshopRegisrationStrings = {
  'fullDateFormat'?: string;
  'startDateFormat'?: string;
  'max-count-reached'?: string;
};

type Data = {
  workshopsRegistrations: WorkshopRegistration[];
} & Record<string, any>;

type WorkshopRegistrationV2Props = FieldProps<any> & {
  ui?: WorkshopRegistrationUIProps;
  strings?: WorkshopRegisrationStrings;
  workshops: Workshop[];
  data: Data;
  registrationErrors?: any[];
  quotaMode?: QuotaMode;
} & Record<string, any>;

function getWorkshopDate(startDate: string, endDate: string, strings: WorkshopRegisrationStrings = {}) {
  const { fullDateFormat = 'workshop-registrations.date-time', startDateFormat = 'workshop-registrations.start-time' } = strings;
  const dateFormat = !!endDate ? fullDateFormat : startDateFormat;
  return i18n.t(dateFormat, { startDate, endDate });
}

export const WorkshopsSummary = (props: WorkshopRegistrationV2Props & { workshops: Workshop[] }) => {
  const { label, style, summary, workshops, data, strings } = props;
  const { workshopsRegistrations = [] } = data;

  const checkedWorkshops = workshops.filter((workshop) => {
    const isChecked = workshopsRegistrations.some((ws) => ws.sessionId === workshop._id);
    return isChecked;
  });

  if (!checkedWorkshops.length) return null;

  return (
    <div className="af-field-container--WorkshopRegistration" style={{ ...style }}>
      <div className="label">{summary?.label || label}</div>
      <div className="list">
        {checkedWorkshops.map((workshop) => {
          const { _id, endDate, sessionCategory, startDate, title } = workshop;
          const date = getWorkshopDate(startDate, endDate, strings);
          return (
            <label key={_id} className="session">
              <div style={{ width: '100%' }}>
                <span>{title}</span> {'   '}
                <span className="date">({date})</span>
                {sessionCategory && <span className="category-tag">{sessionCategory}</span>}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

const { workshops: allWorkshops } = window.__DATA__ as { workshops: Workshop[] };

const WorkshopRegistrationV2 = (props: WorkshopRegistrationV2Props) => {
  const {
    data,
    description,
    label,
    minCount,
    mode,
    maxCount,
    onChange,
    showShortDescription = true,
    strings = {},
    style,
    summary,
    workshops,
    registrationErrors,
    allowOverlappingSessions = false,
    forcedUserCount,
    quotaMode,
    ui,
    disabled: fieldDisabled,
  } = props;

  const { columns, showDateOnly, enableRadio, showCategory = false } = ui || {};

  const { workshopsRegistrations = [], userCount = 1 } = data;
  const workshopsById = useMemo(() => keyBy(workshops, '_id'), [workshops]);
  const fieldWorkshopRegistrations = useMemo(
    () => workshopsRegistrations?.filter((w) => w.sessionId in workshopsById),
    [workshopsRegistrations, workshopsById],
  );
  const registeredWorkshopsById = keyBy(fieldWorkshopRegistrations, 'sessionId');
  const registeredSessions = workshops?.filter((session) => session._id in registeredWorkshopsById);

  const allRegisteredSessions = useMemo(() => {
    const allRegisteredWorkshopsById = keyBy(workshopsRegistrations, 'sessionId');
    return allWorkshops?.filter((session) => session._id in allRegisteredWorkshopsById);
  }, [allWorkshops, workshopsRegistrations]);

  const registrationCount = fieldWorkshopRegistrations.length;
  const hasMaxCount = maxCount && registrationCount >= maxCount;

  const inputMode = minCount === 1 && maxCount === 1 && enableRadio ? 'radio' : 'checkbox';

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { checked = false, name } = target || {};

    const checkedWorkshop = workshops.find((ws) => ws._id === name)!;
    if (checked) {
      if (!hasMaxCount) {
        const newWorkshopRegistrations = [
          ...workshopsRegistrations,
          {
            sessionId: checkedWorkshop._id,
            collection: checkedWorkshop.collection,
          },
        ];
        onChange('workshopsRegistrations', newWorkshopRegistrations);
      }
    } else {
      const newWorkshopRegistrations = workshopsRegistrations.filter((ws) => ws.sessionId !== checkedWorkshop._id);
      onChange('workshopsRegistrations', newWorkshopRegistrations);
    }
  };

  const handleRadioChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { value } = target || {};

    // Uncheck all and check new one
    const [checkedWorkshop, otherWorkshops] = partition(workshops, (ws) => ws._id === value);
    const byKey = keyBy(otherWorkshops, '_id');

    const newRegistrations = workshopsRegistrations.filter((ws) => !(ws.sessionId in byKey));
    if (checkedWorkshop.length) {
      const { _id: sessionId, collection } = checkedWorkshop[0];
      newRegistrations.push({ sessionId, collection });
      onChange('workshopsRegistrations', newRegistrations);
    }
  };

  const { errors } = WorkshopRegistrationV2.validator(props, data);
  if (mode === 'summary') {
    if (summary?.hidden) {
      return null;
    }
    return <WorkshopsSummary {...props} />;
  }

  return (
    <div className={cx('af-field-container--WorkshopRegistration', { 'is-disabled': fieldDisabled })} style={{ ...style }}>
      <div className="label">{label}</div>
      <div className="description">{replaceValues(description, { minCount, maxCount })}</div>
      <div className={cx('list', columns && `list--columns-${columns}`, showDateOnly && 'list--showDateOnly')}>
        {workshops.map((workshop) => {
          const { _id, endDate, startDate, title, category, sessionCategory, shortDescription } = workshop;
          const registrationError = registrationErrors?.find((error) => error.sessionId === _id);
          const registrationsCount = registrationError?.registrationsCount || workshop?.usersCount || 0;
          const sessionQuota = registrationError?.sessionQuota || workshop?.sessionQuota;
          const remainingPlaces = sessionQuota - registrationsCount;
          const workshopFull = !!sessionQuota && (forcedUserCount || userCount) > remainingPlaces;
          const isChecked = workshopsRegistrations.some((ws) => ws.sessionId === _id);
          const date = getWorkshopDate(startDate, endDate, strings);
          const isDisabled =
            !isChecked &&
            (workshopFull ||
              (hasMaxCount && inputMode !== 'radio' && !(workshop._id in registeredWorkshopsById)) ||
              (!allowOverlappingSessions && findOverlappingSession(workshop, allWorkshops ? allRegisteredSessions : registeredSessions)));

          return (
            <label key={_id} style={{ opacity: (fieldDisabled && !isChecked) || isDisabled ? 0.5 : 1 }} className={cx('session', _id)}>
              {inputMode === 'checkbox' && (
                <input type="checkbox" name={_id} disabled={fieldDisabled || isDisabled} checked={isChecked} onChange={handleCheckboxChange} />
              )}
              {inputMode === 'radio' && (
                <input type="radio" name={_id} value={_id} disabled={fieldDisabled || isDisabled} checked={isChecked} onChange={handleRadioChange} />
              )}
              <div style={{ width: '100%' }}>
                <div>
                  {!showDateOnly && (
                    <>
                      <span>{title}</span>
                      <Quota roomLeft={remainingPlaces} quota={sessionQuota} strings={strings} usePercentages={false} quotaMode={quotaMode} />
                    </>
                  )}
                  {sessionCategory && <span className="category-tag">{sessionCategory}</span>}
                  {category && showCategory && <div className="category">{category}</div>}
                </div>
                {/* TODO: ajouter le retour de shortDescription dans registration service V2 */}
                <div className="date">{date}</div>
                {showDateOnly && (
                  <Quota roomLeft={remainingPlaces} quota={sessionQuota} strings={strings} usePercentages={false} quotaMode={quotaMode} />
                )}
                {showShortDescription && shortDescription && <div className="description">{shortDescription}</div>}
              </div>
            </label>
          );
        })}
        {maxCount && hasMaxCount && <Error message={strings['max-count-reached'] || 'Nombre de workshop maximum atteint'} />}
        <Errors name="workshops" errors={errors} />
      </div>
    </div>
  );
};

WorkshopRegistrationV2.defaultProps = {
  label: undefined,
  style: undefined,
  strings: {},
  workshops: [],
  minCount: undefined,
  maxCount: undefined,
  showShortDescription: true,
  registrationErrors: [],
  description: undefined,
  allowOverlappingSessions: false,
};
// WorkshopRegistrationV2.propTypes = {
//   data: PropTypes.object.isRequired,
//   label: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   style: PropTypes.object,
//   strings: PropTypes.object,
//   description: PropTypes.string,
//   minCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   maxCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   registrationErrors: PropTypes.array,
//   showShortDescription: PropTypes.bool,
//   workshops: PropTypes.arrayOf(PropTypes.object),
//   allowOverlappingSessions: PropTypes.bool,
// };

WorkshopRegistrationV2.validator = (field: any, data: Data) => {
  const { minCount, maxCount, strings, workshops } = field;
  const { workshopsRegistrations = [] } = data;

  const workshopsById = keyBy(workshops, '_id');
  const fieldWorkshopRegistrations = workshopsRegistrations.filter((w) => w.sessionId in workshopsById);

  const errors = [
    minCount &&
      fieldWorkshopRegistrations.length < minCount && {
        key: 'registration:minCount',
        message: strings['not-enough-workshop'] || "Vous n'avez pas sélectionné assez de workshops",
      },
    maxCount &&
      fieldWorkshopRegistrations.length > maxCount && {
        key: 'registration:maxCount',
        message: strings['max-count-reached'] || 'Nombre de workshop maximum dépassé',
      },
  ].filter((v) => v);

  return {
    valid: errors.length === 0,
    errors,
  };
};

export default WorkshopRegistrationV2;
